import { FETCH_POST_METADATA_SUCCESS } from '../../actions/fetch-post-metadata';
import { UPDATE_POSTS_RATINGS } from './post-ratings-actions';
import { map, keyBy, mapValues } from 'lodash';
import { FETCH_CATEGORY_POSTS_SUCCESS } from '../../actions/fetch-category-posts';
import { SET_POSTS } from '../../actions/set-posts';
import { FETCH_FEED_POSTS_SUCCESS } from '../../actions/fetch-feed-posts';

const postRatingsReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case SET_POSTS:
    case FETCH_FEED_POSTS_SUCCESS:
    case FETCH_CATEGORY_POSTS_SUCCESS: {
      const nextState = mapValues(
        keyBy(payload, (post) => post._id),
        (post) => ({
          ...state[post._id],
          averageRating: post.averageRating,
          totalRatings: post.totalRatings,
        }),
      );

      return { ...state, ...nextState };
    }
    case FETCH_POST_METADATA_SUCCESS: {
      if (!payload._id) {
        return state;
      }
      return {
        ...state,
        [payload._id]: {
          averageRating: payload.averageRating,
          totalRatings: payload.totalRatings,
        },
      };
    }
    case UPDATE_POSTS_RATINGS:
      const nextState = resolveRatingsNextState(state, payload);

      return {
        ...state,
        ...nextState,
      };
    default:
      return state;
  }
};

const resolveRatingsNextState = (state, ratingsState) => {
  return map(ratingsState, (state, postId) => {
    return state.type === 'READY'
      ? {
          [postId]: {
            averageRating: state.average,
            totalRatings: state.total,
          },
        }
      : {};
  }).reduce((acc, state) => {
    acc = { ...acc, ...state };
    return acc;
  }, {});
};

export default postRatingsReducer;
